import React, { useState } from 'react'

import styled from 'styled-components'

import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  Close as CloseIcon,
  Email as EmailIcon,
  Place as PlaceIcon,
} from '@material-ui/icons'
import ReactGoogleRecaptcha from 'react-google-recaptcha'
import { Fade, Zoom } from 'react-reveal'

import Fab from '../components/Fab'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import materialUiTheme from '../material-ui-theme'

import media from '../utils/media'

const ContactUs = () => {
  const [values, setValues] = useState({
    email: '',
    message: '',
    name: '',
    phone: '',
    subject: '',
    recaptcha: false,
  })
  const [touched, setTouched] = useState(null)
  const [submitError, setSubmitError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
    setTouched({
      ...touched,
      [event.target.name]: true,
    })
  }

  const handleRecaptchaChange = () => {
    setValues({
      ...values,
      recaptcha: true,
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    const fetchURL =
      'https://boyai4nbxb.execute-api.us-west-2.amazonaws.com/beta/send-email'
    const fetchOptions = {
      body: JSON.stringify(mapFormValuesToRequest(values)),
      method: 'POST',
      headers: {
        'X-Api-Key': process.env.GATSBY_MICROS_API_KEY,
      },
    }

    try {
      await fetch(fetchURL, fetchOptions)

      setSubmitSuccess(true)
      setValues({
        email: '',
        message: '',
        name: '',
        phone: '',
        subject: '',
      })
    } catch (error) {
      setSubmitError(true)
    }
  }

  let errors = null
  if (!values.email) {
    errors = {
      ...errors,
      email: 'Email is required',
    }
  }

  if (!values.message) {
    errors = {
      ...errors,
      name: 'Message is required',
    }
  }

  if (!values.name) {
    errors = {
      ...errors,
      name: 'Name is required',
    }
  }

  if (!values.phone) {
    errors = {
      ...errors,
      phone: 'Phone is required',
    }
  }

  if (!values.recaptcha) {
    errors = {
      ...errors,
      recaptcha: 'Recaptcha is required',
    }
  }

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={submitSuccess}
        autoHideDuration={6000}
        onClose={() => setSubmitSuccess(false)}
        ContentProps={{
          'aria-describedby': 'message-success',
        }}
        message={
          <Box component="span" id="message-success">
            Your message has been sent.
          </Box>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => setSubmitSuccess(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={submitError}
        autoHideDuration={6000}
        onClose={() => setSubmitError(false)}
        ContentProps={{
          'aria-describedby': 'message-success',
        }}
        message={
          <Box component="span" id="message-success">
            An error occured while sending your message. Please try again.
          </Box>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => setSubmitError(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Layout>
        <SEO title="Contact Us" />
        <S.Paper>
          <Container>
            <S.GridContainer container>
              <Grid item xs={12} sm={7} md={6}>
                <Zoom>
                  <Typography
                    color="secondary"
                    style={{
                      marginBottom: materialUiTheme.spacing(3),
                    }}
                    variant="h4"
                  >
                    Send us a message
                  </Typography>
                </Zoom>
                <Fade left>
                  <Typography
                    style={{
                      marginBottom: materialUiTheme.spacing(3),
                    }}
                  >
                    You can contact us with anything related to our Products.
                    We'll get in touch with you as soon as possible.
                  </Typography>
                </Fade>
                <Box component="form" onSubmit={handleSubmit}>
                  <Fade left>
                    <TextField
                      fullWidth
                      required
                      label="Name"
                      margin="normal"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Fade>
                  <Fade left>
                    <TextField
                      fullWidth
                      required
                      label="Email address"
                      margin="normal"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Fade>
                  <Fade left>
                    <TextField
                      fullWidth
                      required
                      label="Phone"
                      margin="normal"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                  </Fade>
                  <Fade left>
                    <TextField
                      fullWidth
                      label="Subject"
                      margin="normal"
                      name="subject"
                      value={values.subject}
                      onChange={handleChange}
                    />
                  </Fade>
                  <Fade left>
                    <TextField
                      fullWidth
                      multiline
                      required
                      label="Message"
                      margin="normal"
                      name="message"
                      rows={4}
                      value={values.message}
                      onChange={handleChange}
                    />
                  </Fade>
                  <Fade left>
                    <ReactGoogleRecaptcha
                      sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
                      size={isXsDown ? 'compact' : 'normal'}
                      style={{
                        marginBottom: materialUiTheme.spacing(1),
                        marginTop: materialUiTheme.spacing(2),
                      }}
                      onChange={handleRecaptchaChange}
                    />
                  </Fade>
                  <Fade top>
                    <Fab
                      color="primary"
                      disabled={!!errors}
                      style={{
                        marginTop: materialUiTheme.spacing(2),
                      }}
                      type="submit"
                      variant="extended"
                    >
                      SEND MESSAGE
                    </Fab>
                  </Fade>
                </Box>
              </Grid>
              <S.ContactInfoSectionGridItem
                item
                xs={12}
                sm={4}
                style={{
                  marginTop: theme.spacing(7),
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Fade top>
                      <PlaceIcon color="secondary" fontSize="large" />
                    </Fade>
                  </Grid>
                  <Grid item xs={10}>
                    <Fade top>
                      <Typography variant="h6">Office address</Typography>
                      <Typography>
                        Unit LG-6, Cityland 10 Tower I 156 H.V. Dela Costa St.,
                        Ayala North, Makati City
                      </Typography>
                    </Fade>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginTop: theme.spacing(7),
                  }}
                >
                  <Grid item xs={2}>
                    <Fade top>
                      <EmailIcon color="secondary" fontSize="large" />
                    </Fade>
                  </Grid>
                  <Grid item xs={10}>
                    <Fade top>
                      <Typography variant="h6">Email</Typography>
                      <Typography
                        style={{
                          wordBreak: 'break-all',
                        }}
                      >
                        customer_care@northpark.com.ph
                      </Typography>
                    </Fade>
                  </Grid>
                </Grid>
              </S.ContactInfoSectionGridItem>
            </S.GridContainer>
          </Container>
        </S.Paper>
      </Layout>
    </>
  )
}

const S = {
  ContactInfoSectionGridItem: styled(Grid)`
    margin-left: auto;
  `,
  GridContainer: styled(Grid)`
    ${media.lg`
      padding-left: 130px;
      padding-right: 130px;
    `}
  `,
  Paper: styled(Paper)`
    margin-top: 180px;
    padding: ${materialUiTheme.spacing(8)}px ${materialUiTheme.spacing(2)}px;
  `,
}

const mapFormValuesToRequest = ({
  email: sourceEmail,
  destinationEmail = 'customer_care@northpark.com.ph',
  ...rest
}) => ({
  ...rest,
  destinationEmail,
  sourceEmail,
})

export default ContactUs
